
import { defineComponent } from 'vue'
import Info from '@/assets/svg/info.svg?inline'
import AlertBlock from '@/components/molecules/AlertBlock.vue'
import { Field } from 'vee-validate'
import DefaultPopup from '@/components/molecules/DefaultPopup.vue'
import { Vue3Lottie } from 'vue3-lottie'

export default defineComponent({
  name: 'CreateUnitStepFourSection',
  data () {
    return {
      isLoading: true,
      buildingId: '',
      unitId: '',
      unitData: {},
      detailsData: {},
      testData: [
        {
          title: 'message.property.allow_tenants',
          titleSecondPart: '',
          value: 'allow_tenants_call',
          info: 'true',
          checked: false,
          hasIntegration: true
        },
        {
          title: 'message.property.notify_me_about_started_visits',
          titleSecondPart: '',
          value: 'notify_started_visits',
          checked: false,
          hasIntegration: true
        },
        {
          title: 'message.property.automatically_unpublish_after',
          titleSecondPart: 'message.property.automatically_unpublish_after_second_part',
          value: 'auto_unpublished',
          info: 'true',
          checked: false,
          hasIntegration: true
        },
        {
          title: 'message.property.save_visits_to_my_calendar',
          titleSecondPart: '',
          value: 'save_to_calendar',
          checked: false,
          hasIntegration: true
        },
        {
          title: 'message.property.only_allow_visit',
          titleSecondPart: '',
          value: 'allow_bookings_when_available',
          checked: false,
          hasIntegration: true
        },
        {
          title: 'message.property.only_allow_veriff_verified_visitors',
          titleSecondPart: 'message.property.per_visit',
          value: 'allow_passbase_verified',
          info: 'true',
          checked: false,
          hasIntegration: true
        }
      ],
      error: '',
      errorUnit: '',
      autoUnpublished: 0,
      published: false,
      isIntegration: false,
      isModalOpen: false
    }
  },
  mounted () {
    this.buildingId = this.$route.query.buildingId
    this.unitId = this.$route.query.unitId
    this.getUnit()
    this.integrationsCheckConnection()
  },
  watch: {
    testData: {
      deep: true,
      handler () {
        if (this.errorUnit) {
          this.errorUnit = null
        }
      },
      immediate: true
    }
  },
  components: {
    Info,
    AlertBlock,
    Field,
    DefaultPopup,
    LottieAnimation: Vue3Lottie
  },
  methods: {
    getUnit () {
      this.$store
        .dispatch('property/getUnit', this.unitId)
        .then(
          (res) => {
            this.unitData = res.data
            if (this.unitData.options !== null) {
              this.detailsData = this.unitData.options
              this.testData[0].checked = this.detailsData.allow_tenants_call
              this.testData[1].checked = this.detailsData.notify_started_visits
              this.testData[2].checked = this.detailsData.auto_unpublished
              this.testData[3].checked = this.detailsData.save_to_calendar
              this.testData[4].checked = this.detailsData.allow_bookings_when_available
              this.testData[5].checked = this.detailsData.allow_passbase_verified
              this.autoUnpublished = this.detailsData.auto_unpublished
            }
            if (this.unitData.draft === null) {
              this.draftUnit()
            }
            this.isLoading = false
          },
          (error) => {
            this.error = error.response.data.message
            this.isLoading = false
          }
        )
    },
    integrationsCheckConnection () {
      this.$store.dispatch('integrations/checkConnection', this.unitId).then(
        (res) => {
          this.isIntegration = res.hasIntegration
          this.testData[3].hasIntegration = res.hasIntegration
          this.testData[4].hasIntegration = res.hasIntegration
        },
        (error) => {
          this.error = error.response.data.message
        }
      )
    },
    draftUnit () {
      this.$store.dispatch('property/draftUnit', this.unitId).then(
        (res) => { console.log(res) },
        (error) => {
          this.error = error.response.data.message
        }
      )
    },
    setPropertiesStepFour () {
      this.isLoading = true
      const data = {
        unitId: this.unitId,
        allow_tenants_call: this.testData[0].checked,
        notify_started_visits: this.testData[1].checked,
        auto_unpublished: this.autoUnpublished,
        save_to_calendar: this.testData[3].checked,
        allow_bookings_when_available: this.testData[4].checked,
        allow_passbase_verified: this.testData[5].checked,
        take_offline_on_handover: false
      }
      this.$store
        .dispatch('property/setPropertiesStepFour', data)
        .then(
          () => {
            this.setProperties()
            if (this.published) {
              this.publishUnit()
            }
          },
          (error) => {
            this.errorUnit = error.response.data.errors
            this.isLoading = false
          }
        )
    },
    handleOpenModal (hasIntegration) {
      if (!hasIntegration) {
        this.isModalOpen = true
      }
    },
    handleCloseModal () {
      this.isModalOpen = false
    },
    setPropertiesStepFourAndPublish () {
      this.setPropertiesStepFour()
      this.published = true
    },
    publishUnit () {
      this.$store.dispatch('property/publishUnit', this.unitId).then(
        () => {
          console.log('go')
        },
        (error) => {
          this.error = error.response.data.message
        }
      )
    },
    setProperties () {
      this.$store
        .dispatch('property/setProperties', this.unitId)
        .then(
          () => {
            this.$router.push(
              `/dashboard/unit-details?buildingId=${this.buildingId}&unitId=${this.unitId}`
            )
          },
          (error) => {
            this.errorUnit = error.response.data.errors
          }
        )
    },
    goToThirdStep () {
      this.$router.push(
        `/dashboard/create-unit-step-inventory?buildingId=${this.buildingId}&unitId=${this.unitId}`
      )
    },
    handleDiscard () {
      this.$store.dispatch('property/discardUnit', this.unitId).then(
        () => {
          this.$router.push('/dashboard/property')
        },
        (error) => {
          this.errorUnit = error.response.data.errors
        }
      )
    },
    deleteUnit () {
      this.$store.dispatch('property/deleteUnit', this.unitId).then(
        () => {
          this.$router.push('/dashboard/property')
        },
        (error) => {
          this.error = error.response.data.message
        }
      )
    }
  }
})
